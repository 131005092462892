/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';

import ContactDetailsSection from './steps/contact-details-section';
import ContentSection from './steps/content-section';
import DNStep from '../deposit-now-step/dn-step';
import DepositDetailsSection from './steps/deposit-details-section';
import DepositNowForm from '../deposit-type';
import schema from './review-deposit-schema';
import toastFunctions from 'lib-ui/toast/Toast';
import useDepositDetailsV2 from 'core/api/client-portal-v2/hooks/deposits/use-deposit-details-v2';
import useDepositFileTypes from 'core/api/client-portal/hooks/deposits/use-deposit-file-types';
import useEditDepositV2 from 'core/api/client-portal-v2/hooks/deposits/use-edit-deposit-v2';
import yesOrNo from 'common/yes-or-no';

const ReviewDepositStep = () => {
    const { t } = useTranslation();
    const { depositId } = useParams();

    const goTo = useNavigate();

    const { data, isFetching, isLoading } = useDepositDetailsV2(
        depositId as unknown as number
    );
    const { isLoading: submitting, mutate: updateDeposit } = useEditDepositV2(
        depositId as unknown as number
    );

    const { data: types, isFetched: fetched } = useDepositFileTypes();

    const availableLicensees = useMemo(() => {
        if (!isFetching && data) {
            return Object.keys(
                data.licenseesThatCanBeSelectedForThisDeposit
            ).map((key, index) => ({
                id: (index + 1).toString(),
                label: data.licenseesThatCanBeSelectedForThisDeposit[key],
                value: parseInt(key)
            }));
        } else {
            return [];
        }
    }, [data, isFetching]);

    const selectedLicensees = availableLicensees.filter((licensee) => {
        return data?.details.licenseesThatRequireThisDeposit.includes(
            licensee.value as unknown as number
        );
    });

    const cleanedFileTypes = useMemo(() => {
        if (!fetched || !types) return [];
        return types!.depositFileTypes!.map((fileType) => ({
            id: fileType.id!.toString(),
            label: fileType.description!,
            value: fileType.id!
        }));
    }, [fetched, types]);

    const savedLangs = cleanedFileTypes.filter((lang) => {
        return data?.details.fileTypes.includes(
            lang.value as unknown as string
        );
    });

    if (data?.details.otherFileTypes) {
        savedLangs.push({
            id: '151',
            label: 'Other',
            value: 151
        });
    }

    const method = useForm<DepositNowForm>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: yupResolver(schema),
        values: {
            ...data,
            details: {
                applicationCategory: data?.details.applicationCategory,
                approximateSizeOfMediaContent:
                    data?.details.approximateSizeOfMediaContent,
                containsVirtualMachine: yesOrNo(
                    data?.details.containsVirtualMachine
                ),
                contentsOfThisDeposit: data?.details.contentsOfThisDeposit,
                fileTypes:
                    savedLangs.map((lang) => ({
                        label: lang.label,
                        value: lang.value as unknown as string
                    })) || [],
                financialServicesCategory:
                    data?.details.financialServicesCategory,
                hardwareUsedToCreateTheDeposit:
                    data?.details.hardwareUsedToCreateTheDeposit,
                isForAMediaFileReview: yesOrNo(
                    data?.details.isForAMediaFileReview
                ),
                isForAScheduledVerificationExercise: yesOrNo(
                    data?.details.isForAScheduledVerificationExercise
                ),
                isForASpecificLicensee:
                    data?.details?.isForASpecificLicensee.toString(),
                licenseesThatRequireThisDeposit: selectedLicensees,
                operatingSystemUsed: data?.details.operatingSystemUsed,
                otherFileTypes: data?.details.otherFileTypes,
                softwareOwnerCompanyName:
                    data?.details.softwareOwnerCompanyName,
                softwarePackageName: data?.details.softwarePackageName,
                versionOrReleaseNumber: data?.details.versionOrReleaseNumber
            },
            moreDetails: {
                compressionMethod: data?.moreDetails.compressionMethod,
                containsPersonalData: yesOrNo(
                    data?.moreDetails.containsPersonalData
                ),
                documentation: data?.moreDetails.documentation,
                encryptMethod: data?.moreDetails.encryptMethod,
                exportControlsDetails: data?.moreDetails.exportControlsDetails,
                isCompressed: yesOrNo(data?.moreDetails.isCompressed),
                isEncrypted: yesOrNo(data?.moreDetails?.isEncrypted),
                isEncryptedConfirmation: yesOrNo(
                    data?.moreDetails.isEncryptedConfirmation
                ),
                isPasswordRequired: yesOrNo(
                    data?.moreDetails.isPasswordRequired
                ),
                isReplacementOfPreviousDeposit: yesOrNo(
                    data?.moreDetails.isReplacementOfPreviousDeposit
                ),
                isSubjectToAnyExportControls: yesOrNo(
                    data?.moreDetails.isSubjectToAnyExportControls
                ),
                password: data?.moreDetails.password,
                personalDataDetails: data?.moreDetails.personalDataDetails
            }
        }
    });

    const submit = (formData: any) => {
        const { contactDetails, details, moreDetails, relatedAgreements } =
            formData;

        const newData = {
            contactDetails: {
                addressLine1: contactDetails.addressLine1,
                addressLine2: contactDetails.addressLine2,
                addressLine3: contactDetails.addressLine3,
                city: contactDetails.city,
                companyName: contactDetails.companyName,
                contactName: contactDetails.contactName,
                country: contactDetails.country,
                county: contactDetails.county,
                emailAddress: contactDetails.emailAddress,
                jobTitle: contactDetails.jobTitle,
                postCode: contactDetails.postCode,
                telephoneNumber: contactDetails.telephoneNumber
            },
            dataCentre: data?.dataCentre,
            details: {
                applicationCategory: details.applicationCategory,
                approximateSizeOfMediaContent:
                    details.approximateSizeOfMediaContent,
                containsVirtualMachine:
                    details.containsVirtualMachine === 'yes',
                contentsOfThisDeposit: {
                    configurationFiles:
                        details.contentsOfThisDeposit.configurationFiles,
                    database: details.contentsOfThisDeposit.database,
                    development: details.contentsOfThisDeposit.development,
                    environmentOrBuildTools:
                        details.contentsOfThisDeposit.environmentOrBuildTools,
                    otherData: details.contentsOfThisDeposit.otherData,
                    sourceCode: details.contentsOfThisDeposit.sourceCode,
                    testEnvironment:
                        details.contentsOfThisDeposit.testEnvironment
                },
                fileTypes: details.fileTypes
                    .map((fileType: any) => fileType.value)
                    .filter((value: number) => value !== 151),
                financialServicesCategory: details.financialServicesCategory,
                hardwareUsedToCreateTheDeposit:
                    details.hardwareUsedToCreateTheDeposit,
                isForAMediaFileReview: details.isForAMediaFileReview === 'yes',
                isForAScheduledVerificationExercise:
                    details.isForAScheduledVerificationExercise === 'yes',
                isForASpecificLicensee: details.isForASpecificLicensee,
                licenseesThatRequireThisDeposit:
                    details.isForASpecificLicensee === '1'
                        ? details.licenseesThatRequireThisDeposit.map(
                              (licensee: any) => licensee.value
                          )
                        : [],
                operatingSystemUsed: details.operatingSystemUsed,
                otherFileTypes: details.otherFileTypes,
                softwareOwnerCompanyName: details.softwareOwnerCompanyName,
                softwarePackageName: details.softwarePackageName,
                versionOrReleaseNumber: details.versionOrReleaseNumber
            },
            moreDetails: {
                compressionMethod: moreDetails.compressionMethod,
                containsPersonalData:
                    moreDetails.containsPersonalData === 'yes',
                documentation: moreDetails.documentation,
                encryptMethod: moreDetails.encryptMethod,
                exportControlsDetails: moreDetails.exportControlsDetails,
                isCompressed: moreDetails.isCompressed === 'yes',
                isEncrypted: moreDetails.isEncrypted === 'yes',
                isEncryptedConfirmation: moreDetails.isEncrypted === 'yes',
                isPasswordRequired: moreDetails.isPasswordRequired === 'yes',
                isReplacementOfPreviousDeposit:
                    moreDetails.isReplacementOfPreviousDeposit === 'yes',
                isSubjectToAnyExportControls:
                    moreDetails.isSubjectToAnyExportControls === 'yes',
                password: moreDetails.password,
                personalDataDetails: moreDetails.personalDataDetails
            },
            relatedAgreements: relatedAgreements
        };

        updateDeposit(newData, {
            onError() {
                toastFunctions.error(t('toast.error'));
            },
            onSuccess() {
                toastFunctions.success(t('toast.success'));
                goTo(`/deposit-now/${depositId}/disclaimer`);
            }
        });
    };

    return (
        <FormProvider {...method}>
            <form onSubmit={method?.handleSubmit(submit)}>
                <DNStep
                    classNames="flex flex-col gap-4 mb-2"
                    loading={isLoading && isFetching}
                    disableBack
                    submitting={submitting}
                    title={t('deposit-now-stage-five.title')}
                    description={t('deposit-now-stage-five.description')}
                >
                    <ContactDetailsSection />

                    <DepositDetailsSection
                        data={data}
                        isFetched={!isFetching}
                        method={method}
                    />

                    <ContentSection method={method} />
                </DNStep>
            </form>
        </FormProvider>
    );
};

export default ReviewDepositStep;
