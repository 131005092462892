import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TailwindProps } from '@ncc-frontend/core';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import cn from 'core/utils/cn';

interface SearchBarProps
    extends DetailedHTMLProps<
            InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >,
        TailwindProps {
    inputStyles?: string;
    width?: string;
}

function SearchBar({
    className,
    inputStyles,
    tailwindStyle,
    width,
    ...restProps
}: SearchBarProps) {
    return (
        <div
            className={cn(
                'h-10 relative text-sm',
                !width && 'max-w-xl',
                tailwindStyle,
                className
            )}
        >
            <input
                data-testid="input"
                className={cn(
                    'h-full w-full px-6 rounded-lg pl-9 placeholder:text-general-grey-grey-70',
                    inputStyles
                )}
                {...restProps}
            />
            <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 -translate-y-1/2 text-general-grey-grey-70"
            />
        </div>
    );
}

export default forwardRef(SearchBar);
export type { SearchBarProps };
