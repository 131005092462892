import { useTranslation } from 'react-i18next';

import AutomateFutureDeposits from './automate-future-deposits';
import ControlConnections from './control-connections';

type SourceControlDepositProps = {
    automate: string;
};
const SourceControlDeposits = ({ automate }: SourceControlDepositProps) => {
    const { t } = useTranslation();

    return (
        <section
            className="flex flex-col w-full gap-12 pt-5"
            data-testid="layout-section"
        >
            <div className="flex w-full flex-col">
                <h2 className="text-sm text-general-grey-grey-100 font-normal mb-2">
                    {t('source-control.control-connections')}
                </h2>

                <ControlConnections />
            </div>
            <div className="flex min-w-72 flex-col">
                <div>
                    <h2 className="text-sm text-general-grey-grey-100 font-normal">
                        {t('source-control.automate')}
                    </h2>
                    <AutomateFutureDeposits isAutomated={automate === 'true'} />
                </div>
            </div>
        </section>
    );
};

export default SourceControlDeposits;
