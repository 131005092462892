import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { isUndefined } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';

import { Button, type MultiCheckboxFieldProps } from 'lib-ui';
import { useConnectionsStore } from 'core/stores/use-connections-store';
import AddBranchSelectionModal from './add-branch-selection-modal/add-branch-selection-modal';
import cn from 'core/utils/cn';
import useGetProviders from 'core/api/client-portal/hooks/source-control-deposits/use-get-providers';
import type { DownloadScheduleRepo } from 'core/api/client-portal/autogenerated/data-contracts';

const ControlConnections = () => {
    const { t } = useTranslation();
    const methods = useFormContext();
    const { push } = useModals();
    const { connections, removeConnection, setConnections } =
        useConnectionsStore();

    const { data: providers, isFetching } = useGetProviders({});

    const providersIdsOptions = useMemo<MultiCheckboxFieldProps['options']>(
        () =>
            providers?.providers
                ?.filter(
                    (item) =>
                        !isUndefined(item.connectionName) &&
                        !isUndefined(item.id)
                )
                .map((item) => ({
                    label: `${item.connectionName} - ${item.id}`,
                    value: item.id as number
                })) ?? [],
        [providers]
    );

    const getProviderName = useCallback(
        (id?: number) => {
            const provider = providers?.providers?.find(
                (item) => item.id === Number(id)
            );
            return provider?.connectionName ?? '';
        },
        [providers]
    );

    const selectConnection = useCallback(() => {
        !isFetching &&
            push(AddBranchSelectionModal, {
                currentConnections: connections,
                methods,
                providersIdsOptions
            });
    }, [isFetching, methods, providersIdsOptions, push, connections]);

    const handleRemoveConnection = useCallback(
        (item: DownloadScheduleRepo) => {
            const updatedConnections = connections.filter(
                (connection) => connection.providerId !== item.providerId
            );
            setConnections(updatedConnections);
            methods.setValue('connectionsAdded', updatedConnections);
            removeConnection(item.providerId!);
        },
        [connections, methods, removeConnection, setConnections]
    );

    useEffect(() => {
        const subscription = methods.watch((value, { name }) => {
            if (name === 'connections') {
                setConnections(value.connections || []);
            }
        });

        return () => {
            if (
                subscription &&
                typeof subscription.unsubscribe === 'function'
            ) {
                subscription.unsubscribe();
            }
        };
    }, [methods, setConnections]);

    return (
        <div
            className={cn(
                'rounded-lg border border-general-grey-grey-20 p-6 bg-general-grey-grey-10 flex flex-col items-center gap-4',
                connections.length > 0 && 'bg-white'
            )}
            data-testid="connections-container"
        >
            {connections.length === 0 && (
                <div className="mt-4 text-general-grey-grey-60 text-xs">
                    <span>{t('source-control.no-connections')}</span>
                </div>
            )}
            {connections.length > 0 && (
                <table
                    className="w-full items-center"
                    data-testid="connections-table"
                >
                    <thead className="border-b-2 border-general-grey-grey-30 text-general-grey-grey-80 text-xs font-normal py-3">
                        <tr className="py-3">
                            <th className="text-left py-3">
                                {t('source-control.name')}
                            </th>
                            <th className="text-left py-3">
                                {t('source-control.whole-branch')}
                            </th>
                            <th className="text-left py-3">
                                {t('source-control.actions')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {connections.map((item, index) => (
                            <tr
                                className="border-b-2 border-general-grey-grey-30"
                                key={index}
                            >
                                <td className="text-left text-xs text-general-grey-grey-100">
                                    <FontAwesomeIcon
                                        icon={faCodeBranch}
                                        className="icon-large text-brand-escode-neonblue-neonblue-100 mr-2"
                                    />
                                    {getProviderName(item?.providerId)}
                                </td>
                                <td className="text-left text-xs text-general-grey-grey-100">
                                    {item.branchName ??
                                        t('source-control.whole-repository')}
                                </td>
                                <td className="text-left text-xs text-general-grey-grey-100">
                                    <Button
                                        onClick={() =>
                                            handleRemoveConnection(item)
                                        }
                                        type="button"
                                        variant="danger"
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            className="icon-large text-general-red-red-100"
                                        />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <div className="flex items-center gap-14 text-center">
                <div className="flex gap-2">
                    <Button
                        onClick={selectConnection}
                        type="button"
                        variant="tertiary"
                    >
                        {t('source-control.select-connection')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ControlConnections;
