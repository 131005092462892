import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircleDot } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';

import cn from 'core/utils/cn';

type RadioButtonProps = {
    label: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    val: boolean;
};

const RadioButton = ({ label, onClick, val }: RadioButtonProps) => {
    return (
        <button
            className={cn(
                'px-4 py-[10px] border-2 border-general-grey-grey-40 rounded-lg flex gap-2 items-center',
                val && 'border-2 border-brand-escode-neonblue-neonblue-80'
            )}
            onClick={onClick}
            type="button"
        >
            {val ? (
                <FontAwesomeIcon
                    icon={faCircleDot}
                    className="text-brand-escode-neonblue-neonblue-80"
                />
            ) : (
                <FontAwesomeIcon
                    icon={faCircle}
                    className="text-general-grey-grey-40"
                />
            )}
            {label}
        </button>
    );
};

export default RadioButton;
