import { DownloadScheduleRepo } from 'core/api/client-portal/autogenerated/data-contracts';
import { create } from 'zustand';

interface ConnectionsStore {
    addConnection: (connection: DownloadScheduleRepo) => void;
    connections: DownloadScheduleRepo[];
    removeConnection: (providerId: number) => void;
    setConnections: (connections: DownloadScheduleRepo[]) => void;
}

export const useConnectionsStore = create<ConnectionsStore>((set) => ({
    addConnection: (connection) =>
        set((state) => ({
            connections: [...state.connections, connection]
        })),
    connections: [],
    removeConnection: (providerId) =>
        set((state) => ({
            connections: state.connections.filter(
                (conn) => conn.providerId !== providerId
            )
        })),
    setConnections: (connections) => set(() => ({ connections }))
}));
