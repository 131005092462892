import { DepositItem } from 'core/api/client-portal/autogenerated/data-contracts';
import { ICellRendererParams } from 'lib-ui';
import { memo } from 'react';

function DepositNameCellRenderer({ data }: ICellRendererParams<DepositItem>) {
    return (
        <div
            className="flex h-full items-center max-w-[450px] truncate"
            data-testid="deposit-name-container"
            title={
                data.versionOrReleaseNumber !== ''
                    ? `${data.softwarePackageName} (${data.versionOrReleaseNumber})`
                    : `${data.softwarePackageName}`
            }
        >
            <p className="truncate">
                {data.versionOrReleaseNumber === ''
                    ? data.softwarePackageName
                    : `${data.softwarePackageName} (${data.versionOrReleaseNumber})`}
            </p>
        </div>
    );
}

export default memo(DepositNameCellRenderer);
