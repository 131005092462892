/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ChoiceField,
    InputField,
    RadioGroupField,
    Section,
    SelectionField,
    StepSection,
    SwitchField
} from 'lib-ui';
import {
    applicationCategoryOptions,
    financialServicesOptions,
    radioLicenseesOptions,
    radioOptions
} from 'ui/deposit-now/deposit-details-step/deposit-details-options';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DepositNowForm from 'ui/deposit-now/deposit-type';
import useAgreementDetailsV2 from 'core/api/client-portal-v2/hooks/agreement/use-agreement-details-v2';
import useDepositFileTypes from 'core/api/client-portal/hooks/deposits/use-deposit-file-types';

type StepThreeProps = {
    data: DepositNowForm;
    isFetched: boolean;
    method: any;
};

const DepositDetailsSection = ({ data, isFetched, method }: StepThreeProps) => {
    const { t } = useTranslation();
    const [disableLicensee, setDisableLicensee] = useState(true);
    const { setValue } = useForm();

    const { data: types, isFetched: fetched } = useDepositFileTypes();

    const cleanedFileTypes = useMemo(() => {
        if (!fetched || !types) return [];
        const formattedTypes = types!.depositFileTypes!.map((fileType) => ({
            id: fileType.id!.toString(),
            label: fileType.description!,
            value: fileType.id!
        }));
        formattedTypes.push({ id: '151', label: 'Other', value: 151 });
        return formattedTypes.sort((a, b) => a.label.localeCompare(b.label));
    }, [fetched, types]);

    const availableLicensees = useMemo(() => {
        if (isFetched && data) {
            return Object.keys(
                data.licenseesThatCanBeSelectedForThisDeposit
            ).map((key, index) => ({
                id: (index + 1).toString(),
                label: data.licenseesThatCanBeSelectedForThisDeposit[key],
                value: parseInt(key)
            }));
        } else {
            return [];
        }
    }, [data, isFetched]);

    const { data: agreement } = useAgreementDetailsV2(
        data?.relatedAgreements[0],
        { enabled: !!data }
    );

    const savedLicensees = availableLicensees.filter((licensee) => {
        return data?.details.licenseesThatRequireThisDeposit.includes(
            licensee.value as unknown as number
        );
    });

    const selectedLangs = cleanedFileTypes.filter((lang) => {
        return data?.details.fileTypes.includes(
            lang.value as unknown as string
        );
    });

    if (data?.details.otherFileTypes) {
        selectedLangs.push({
            id: '151',
            label: 'Other',
            value: 151
        });
    }

    const isMediaFile = useWatch({ name: 'details.isForAMediaFileReview' });
    const isScheduled = useWatch({
        name: 'details.isForAScheduledVerificationExercise'
    });
    const containsVM = useWatch({ name: 'details.containsVirtualMachine' });
    const allLicensees =
        useWatch({ name: 'details.isForASpecificLicensee' }) === '0';
    const selectedLicensees =
        useWatch({ name: 'details.isForASpecificLicensee' }) === '1';
    const specificLicensees = useWatch({
        name: 'details.licenseesThatRequireThisDeposit'
    });
    const sourceCode = useWatch({
        name: 'details.contentsOfThisDeposit.sourceCode'
    });
    const fileTypes:
        | string[]
        | {
              label: string;
              value: string;
          }[]
        | undefined = useWatch({ name: 'details.fileTypes' });

    const fileTypesIncludesOther = fileTypes?.some((types) => {
        return typeof types !== 'string' && types.label === 'Other';
    });

    useEffect(() => {
        if (specificLicensees?.length > 0 && !allLicensees) {
            setValue('details.isForASpecificLicensee', '1');
        }
        if (specificLicensees?.length > 0 && allLicensees) {
            setValue('details.licenseesThatRequireThisDeposit', []);
        }
    }, [specificLicensees, allLicensees, setValue]);

    useEffect(() => {
        if (isScheduled === 'yes' || containsVM === 'yes') {
            setDisableLicensee(false);
            return;
        }
        if (isScheduled === 'no' && containsVM === 'no') {
            setDisableLicensee(true);
            setValue('details.isForASpecificLicensee', '0');
            setValue('details.licenseesThatRequireThisDeposit', []);
            return;
        }
    }, [allLicensees, containsVM, isScheduled, setValue]);

    useEffect(() => {
        if (isMediaFile === 'yes') {
            setValue('details.isForAScheduledVerificationExercise', 'no');
            setValue('details.containsVirtualMachine', 'no');
        } else if (isScheduled === 'yes') {
            setValue('details.isForAMediaFileReview', 'no');
            setValue('details.containsVirtualMachine', 'no');
        } else if (containsVM === 'yes') {
            setValue('details.isForAMediaFileReview', 'no');
            setValue('details.isForAScheduledVerificationExercise', 'no');
        }
    }, [isMediaFile, isScheduled, containsVM, setValue]);

    useEffect(() => {
        if (!sourceCode) {
            setValue('details.fileTypes', []);
            setValue('details.otherFileTypes', '');
        }
    }, [setValue, sourceCode]);

    return (
        <StepSection stepTitle={t('deposit-now-stage-three.title')}>
            <Section className="border-none p-0 bg-general-grey-grey-10">
                <div className="flex h-auto gap-5">
                    <Section
                        heading={t('deposit-now-stage-three.software-details')}
                        required
                    >
                        <InputField
                            disabled
                            name="details.softwareOwnerCompanyName"
                            label={t(
                                'deposit-now-stage-three.company-owner-title'
                            )}
                            placeholder={t(
                                'deposit-now-stage-two.company-name'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                        />
                        <InputField
                            disabled
                            label={t(
                                'deposit-now-stage-three.company-package-title'
                            )}
                            name="details.softwarePackageName"
                            placeholder={t(
                                'deposit-now-stage-two.company-name'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                        />
                        <InputField
                            label={t('deposit-now-stage-three.version')}
                            name="details.versionOrReleaseNumber"
                            placeholder={t(
                                'deposit-now-stage-three.version-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            tooltip="deposit-now-stage-three.version-tooltip"
                            tooltipPlacement="right"
                        />

                        <RadioGroupField
                            label={t(
                                'deposit-now-stage-three.deposit-scheduled'
                            )}
                            name="details.isForAScheduledVerificationExercise"
                            options={radioOptions}
                            tooltip="deposit-now-stage-three.deposit-scheduled-tooltip"
                        />

                        <RadioGroupField
                            label={t('deposit-now-stage-three.deposit-media')}
                            name="details.isForAMediaFileReview"
                            options={radioOptions}
                            tooltip="deposit-now-stage-three.deposit-media-tooltip"
                        />
                        <RadioGroupField
                            label={t('deposit-now-stage-four.deposit-virtual')}
                            name="details.containsVirtualMachine"
                            options={radioOptions}
                            required
                            tooltip="deposit-now-stage-four.deposit-vm-tooltip"
                        />
                    </Section>

                    <div className="col-span-2 grid grid-cols-1 gap-5 flex-1">
                        {agreement?.isMultiAgreementType &&
                            availableLicensees.length > 0 && (
                                <Section
                                    heading={t(
                                        'deposit-now-stage-three.licensee-details'
                                    )}
                                    required
                                >
                                    <RadioGroupField
                                        disabled={disableLicensee}
                                        label={t(
                                            'deposit-now-stage-three.specific-licensee'
                                        )}
                                        id="details.isForASpecificLicensee"
                                        name="details.isForASpecificLicensee"
                                        options={radioLicenseesOptions}
                                        tooltip="deposit-now-stage-three.licensee-details-tooltip"
                                        radioStyles="gap-4"
                                    />

                                    {selectedLicensees && (
                                        <SelectionField
                                            closeMenuOnSelect={false}
                                            defaultValue={savedLicensees}
                                            id="details.licenseesThatRequireThisDeposit"
                                            isMulti
                                            label={t(
                                                'deposit-now-stage-three.specify-licensees'
                                            )}
                                            loading={!savedLicensees}
                                            name="details.licenseesThatRequireThisDeposit"
                                            options={availableLicensees}
                                            placeholder={t(
                                                'deposit-now-stage-three.source-placeholder'
                                            )}
                                        />
                                    )}
                                </Section>
                            )}

                        <Section
                            heading={t(
                                'deposit-now-stage-three.application-details'
                            )}
                            optional
                        >
                            <ChoiceField
                                label={t(
                                    'deposit-now-stage-three.financial-services'
                                )}
                                name="details.financialServicesCategory"
                                placeholder={t(
                                    'deposit-now-stage-three.banking-type'
                                )}
                                options={financialServicesOptions}
                                tailwindStyle={{
                                    width: 'w-full'
                                }}
                            />

                            <ChoiceField
                                label={t(
                                    'deposit-now-stage-three.application-category'
                                )}
                                name="details.applicationCategory"
                                placeholder={t(
                                    'deposit-now-stage-three.banking-app'
                                )}
                                options={applicationCategoryOptions}
                                tailwindStyle={{
                                    width: 'w-full'
                                }}
                            />
                        </Section>
                    </div>

                    <Section heading={t('deposit-now-stage-deposit.specs')}>
                        <Section
                            className="border-none p-0"
                            data-testid="contentsOfThisDeposit"
                            heading={t(
                                'deposit-now-stage-three.deposit-content'
                            )}
                            headingClasses="mb-2"
                            headingTextClasses="text-base font-normal text-general-grey-grey-90"
                        >
                            <div className="grid grid-cols-2 gap-2 flex-1">
                                <SwitchField
                                    label="Source Code"
                                    name="details.contentsOfThisDeposit.sourceCode"
                                    id="details.contentsOfThisDeposit.sourceCode"
                                />
                                <SwitchField
                                    label="Development"
                                    name="details.contentsOfThisDeposit.development"
                                    id="details.contentsOfThisDeposit.development"
                                />
                                <SwitchField
                                    label="Environment or Build Tools"
                                    name="details.contentsOfThisDeposit.environmentOrBuildTools"
                                    id="details.contentsOfThisDeposit.environmentOrBuildTools"
                                />
                                <SwitchField
                                    label="Database"
                                    name="details.contentsOfThisDeposit.database"
                                    id="details.contentsOfThisDeposit.database"
                                />
                                <SwitchField
                                    label="Test Environment"
                                    name="details.contentsOfThisDeposit.testEnvironment"
                                    id="details.contentsOfThisDeposit.testEnvironment"
                                />
                                <SwitchField
                                    label="Other Data"
                                    name="details.contentsOfThisDeposit.otherData"
                                    id="details.contentsOfThisDeposit.otherData"
                                />
                                <SwitchField
                                    label="Configuration Files"
                                    name="details.contentsOfThisDeposit.configurationFiles"
                                    id="details.contentsOfThisDeposit.configurationFiles"
                                />
                            </div>
                            {method.formState?.errors?.details
                                ?.contentsOfThisDeposit && (
                                <div className="text-sm text-general-red-red-100">
                                    <>
                                        {
                                            method.formState?.errors?.details
                                                ?.contentsOfThisDeposit.message
                                        }
                                    </>
                                </div>
                            )}
                        </Section>
                        <InputField
                            label={t('deposit-now-stage-three.os')}
                            name="details.operatingSystemUsed"
                            optional
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            tooltip="deposit-now-stage-three.os-tooltip"
                        />
                        <InputField
                            label={t('deposit-now-stage-three.hardware')}
                            name="details.hardwareUsedToCreateTheDeposit"
                            optional
                            placeholder={t(
                                'deposit-now-stage-two.job-placeholder'
                            )}
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                            tooltip="deposit-now-stage-three.hardware-tooltip"
                        />
                        {sourceCode && fetched && (
                            <SelectionField
                                closeMenuOnSelect={false}
                                defaultValue={selectedLangs}
                                id="fileTypes"
                                isMulti
                                label={t('deposit-now-stage-three.source-code')}
                                loading={!selectedLangs}
                                name="details.fileTypes"
                                options={cleanedFileTypes}
                                placeholder={t(
                                    'deposit-now-stage-three.source-placeholder'
                                )}
                                tooltip="deposit-now-stage-three.source-code-tooltip"
                            />
                        )}
                        {fileTypesIncludesOther && fetched && (
                            <InputField
                                label={t('deposit-now-stage-three.other')}
                                name="details.otherFileTypes"
                                placeholder={t(
                                    'deposit-now-stage-three.other-placeholder'
                                )}
                                required
                                tailwindStyle={{
                                    width: 'w-full'
                                }}
                            />
                        )}
                        <InputField
                            label={t('deposit-now-stage-three.content-size')}
                            name="details.approximateSizeOfMediaContent"
                            placeholder={t('deposit-now-stage-three.maximum')}
                            required
                            tailwindStyle={{
                                width: 'w-full'
                            }}
                        />
                    </Section>
                </div>
            </Section>
        </StepSection>
    );
};

export default DepositDetailsSection;
