import {
    Controller,
    type FieldValues,
    type UseFormReturn,
    useFieldArray,
    useForm
} from 'react-hook-form';
import { type ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useTranslation } from 'react-i18next';
import { useVirtualizer } from '@tanstack/react-virtual';
import type { DownloadScheduleRepo } from 'core/api/client-portal/autogenerated/data-contracts';

import { Button, Input, Modal } from 'lib-ui';
import AddSourceControlConnectionModal from 'ui/source-control-connections/add-source-control-connection-modal/add-source-control-connection-modal';
import Checkbox from './checkbox';
import SearchBar from 'lib-ui/search-bar/search-bar';
import cn from 'core/utils/cn';
import toastFunctions from 'lib-ui/toast/Toast';
import useVerifyRepositoryConfiguration from 'core/api/client-portal/hooks/source-control-deposits/use-verify-repository-configuration';

export type AddBranchSelectionModalProps = {
    currentConnections: DownloadScheduleRepo[];
    methods: UseFormReturn<FieldValues, unknown>;
    providersIdsOptions: {
        label: string | ReactNode;
        value: string | number;
    }[];
};

export default function AddBranchSelectionModal({
    currentConnections,
    methods,
    providersIdsOptions
}: AddBranchSelectionModalProps) {
    const { t } = useTranslation();
    const { pop, push } = useModals();
    const [searchQuery, setSearchQuery] = useState('');
    const parentRef = useRef<HTMLDivElement>(null);
    const [branchErrors, setBranchErrors] = useState<string[]>([]);

    const { control, handleSubmit, setValue, watch } = useForm<{
        repositories: Array<{
            branchName: string | null;
            id: number;
            isSelected: boolean;
            label: string;
            providerId: number;
            selectBranch: boolean;
        }>;
    }>({
        defaultValues: {
            repositories: providersIdsOptions
                .filter(
                    (connection) =>
                        !currentConnections.some(
                            (existingConnection) =>
                                existingConnection.providerId ===
                                connection.value
                        )
                )
                .map((option) => ({
                    branchName: null,
                    id: Number(option.value),
                    isSelected: false,
                    label: option.label!.toString(),
                    providerId: Number(option.value),
                    selectBranch: false
                }))
        }
    });

    const { fields } = useFieldArray({
        control,
        name: 'repositories'
    });

    function errorFormatter(errorMessage: string) {
        switch (errorMessage) {
            case 'BadCredentials':
                return `Invalid Credentials`;

            case 'BadBranch':
                return `Invalid Branch`;

            case 'UnknownError':
                return `Unknown Error`;

            default:
                t('toast.control-connection-error');
        }
        return errorMessage;
    }

    const { isLoading, mutate: mutateFn } = useVerifyRepositoryConfiguration({
        onError(error) {
            toastFunctions.error(`${error}`, 'duration-500');
        },
        onSuccess(data, variables, context) {
            const { errors } = data.data;

            if (Object.keys(errors).length > 0) {
                const matchedConnections = Object.keys(errors).map((key) => {
                    return {
                        error: errors[key][0],
                        label: providersIdsOptions
                            .find((option) => {
                                return option.value === Number.parseInt(key);
                            })
                            ?.label?.toString()
                            .split('-')[0]
                    };
                });
                setBranchErrors(Object.keys(errors));

                matchedConnections.forEach((connection) => {
                    toastFunctions.error(
                        `${connection.label} - ${errorFormatter(
                            connection.error
                        )}`,
                        'duration-500'
                    );
                });

                return;
            }
            toastFunctions.success(t('toast.control-connection-success'));
            setBranchErrors([]);

            const newConnections = [
                ...currentConnections,
                ...watch('repositories').filter((repo) => repo.isSelected)
            ];
            methods.setValue('connections', newConnections);
            setTimeout(() => {
                pop();
            }, 800);
        }
    });

    const handleAddNewConnection = useCallback(() => {
        push(AddSourceControlConnectionModal, {});
    }, [push]);

    const filteredConnections = useMemo(() => {
        // If no search query, return all fields
        if (!searchQuery) {
            return fields;
        }
        // Otherwise, filter based on search
        return fields.filter((repo) => {
            const label = providersIdsOptions.find(
                (option) => option.label === repo.label
            )?.label;

            return label
                ? label
                      .toString()
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                : false;
        });
    }, [fields, providersIdsOptions, searchQuery]);

    const rowVirtualizer = useVirtualizer({
        count: filteredConnections.length,
        estimateSize: () => 80,
        getScrollElement: () => parentRef.current,
        overscan: 25
    });

    const onSubmit = handleSubmit((data) => {
        const selectedRepositories = data.repositories.filter(
            (repo) => repo.isSelected
        );
        methods.setValue('connectionsAdded', selectedRepositories);
        mutateFn({
            data: selectedRepositories
        });
    });

    return (
        <Modal size="l" className="">
            <Modal.Header>
                <div className="flex w-full items-center justify-between">
                    {t('add-source-control-connection.title')}
                </div>
            </Modal.Header>
            <div className="h-10 mt-3">
                <SearchBar
                    placeholder="Search connections..."
                    width="w-full"
                    inputStyles="rounded-lg bg-general-grey-grey-10 absolute top-0 left-0"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>
            <div className="flex justify-between mt-5">
                <div className="flex items-center px-1">
                    <input
                        type="checkbox"
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            fields.forEach((_, index) => {
                                setValue(
                                    `repositories.${index}.isSelected`,
                                    isChecked
                                );
                            });
                        }}
                    />
                    <p className="ml-2 text-sm text-general-grey-grey-100">
                        {t('source-control.select-all')}
                    </p>
                </div>
                <p className="text-xs text-general-grey-grey-100 font-normal">
                    {filteredConnections.length}{' '}
                    <span className="text-general-grey-grey-80">
                        connections
                    </span>
                </p>
            </div>
            <Modal.Body className="relative mt-0">
                {filteredConnections.length === 0 ? (
                    <div className="flex items-center justify-center h-40">
                        <p className="text-general-grey-grey-60">
                            {searchQuery
                                ? t('No matching connections found')
                                : t('No connections available')}
                        </p>
                    </div>
                ) : (
                    <div
                        ref={parentRef}
                        className="h-[70rem] overflow-auto mt-0.5 pl-[2px]"
                    >
                        <div
                            className="relative w-full"
                            style={{
                                height: `${rowVirtualizer.getTotalSize()}px`
                            }}
                        >
                            {rowVirtualizer
                                .getVirtualItems()
                                .map((virtualItem) => {
                                    const item =
                                        filteredConnections[virtualItem.index];
                                    const fieldIndex = fields.findIndex(
                                        (f) => f.id === item.id
                                    );
                                    return (
                                        <div
                                            key={virtualItem.index}
                                            className="absolute top-0 left-0 w-full"
                                            style={{
                                                height: `${virtualItem.size}px`,
                                                transform: `translateY(${virtualItem.start}px)`
                                            }}
                                        >
                                            <div className="flex w-full gap-2 items-center justify-between border-b border-gray-500 py-5">
                                                <div
                                                    className="flex w-[46%] min-w-48 gap-3 text-nowrap items-center"
                                                    title={
                                                        item
                                                            .label!.toString()
                                                            .split('-')[0]
                                                    }
                                                >
                                                    <Controller
                                                        name={`repositories.${fieldIndex}.isSelected`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Checkbox
                                                                id={`wholeRepository-${item.id}`}
                                                                value="wholeRepository"
                                                                name={String(
                                                                    item.id
                                                                )}
                                                                checked={
                                                                    field.value
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    field.onChange(
                                                                        e.target
                                                                            .checked
                                                                    );
                                                                    if (
                                                                        !e
                                                                            .target
                                                                            .checked
                                                                    ) {
                                                                        setValue(
                                                                            `repositories.${fieldIndex}.selectBranch`,
                                                                            false
                                                                        );
                                                                        setValue(
                                                                            `repositories.${fieldIndex}.branchName`,
                                                                            null
                                                                        );
                                                                    }
                                                                }}
                                                                label={
                                                                    item
                                                                        .label!.toString()
                                                                        .split(
                                                                            '-'
                                                                        )[0]
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div className="flex flex-1 items-center justify-between gap-2 text-sm">
                                                    <div className="flex flex-1 items-center justify-end gap-4 text-sm w-[60%]">
                                                        <div className="flex items-center text-wrap">
                                                            <Controller
                                                                name={`repositories.${fieldIndex}.selectBranch`}
                                                                control={
                                                                    control
                                                                }
                                                                render={({
                                                                    field
                                                                }) => (
                                                                    <Checkbox
                                                                        id={`selectBranch-${item.id}`}
                                                                        name={String(
                                                                            item.id
                                                                        )}
                                                                        value="selectBranch"
                                                                        checked={
                                                                            field.value
                                                                        }
                                                                        disabled={
                                                                            !watch(
                                                                                `repositories.${fieldIndex}.isSelected`
                                                                            )
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) => {
                                                                            field.onChange(
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            );
                                                                            if (
                                                                                !e
                                                                                    .target
                                                                                    .checked
                                                                            ) {
                                                                                setValue(
                                                                                    `repositories.${fieldIndex}.branchName`,
                                                                                    null
                                                                                );
                                                                            }
                                                                        }}
                                                                        label={t(
                                                                            'add-source-control-connection.select-branch'
                                                                        )}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                        <Controller
                                                            name={`repositories.${fieldIndex}.branchName`}
                                                            control={control}
                                                            render={({
                                                                field
                                                            }) => (
                                                                <Input
                                                                    {...field}
                                                                    id={String(
                                                                        item.id
                                                                    )}
                                                                    className={cn(
                                                                        'w-52',
                                                                        branchErrors.includes(
                                                                            item.providerId.toString()
                                                                        ) &&
                                                                            'border-general-red-red-100'
                                                                    )}
                                                                    placeholder="Branch name"
                                                                    disabled={
                                                                        !watch(
                                                                            `repositories.${fieldIndex}.selectBranch`
                                                                        )
                                                                    }
                                                                    required={watch(
                                                                        `repositories.${fieldIndex}.selectBranch`
                                                                    )}
                                                                    value={
                                                                        field.value ??
                                                                        ''
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer showCloseButtonAtEnd>
                <div className="flex w-full gap-4 justify-between">
                    <Button
                        onClick={handleAddNewConnection}
                        variant="secondary"
                    >
                        {t('source-control.add-connection')}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={
                            !watch('repositories').some(
                                (repo) => repo.isSelected
                            )
                        }
                    >
                        {t('source-control.save')}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
