import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';

interface ModalBodyProps extends CommonProps {}

function ModalBody({
    children,
    className,
    ...restProps
}: PropsWithChildren<ModalBodyProps>): ReactElement | null {
    const cssClasses = classNames(
        'overflow-auto mt-escode-l px-[0.20rem]',
        className
    );

    return (
        <div {...restProps} className={cssClasses} data-testid="modal-body">
            {children}
        </div>
    );
}

export default ModalBody;
export type { ModalBodyProps };
