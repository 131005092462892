import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxField, RadioGroupField } from 'lib-ui';
import { Frequency } from './frequency-enum';
import { FrequencySection } from './frequency-section';
import {
    frequencyOptions,
    hoursOptions,
    minutesOptions
} from './provider-scd-utils';
import RadioButton from './radio-button';

type AutomateFutureDepositsParams = {
    isAutomated?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const AutomateFutureDeposits = ({
    isAutomated = false
}: AutomateFutureDepositsParams) => {
    const { t } = useTranslation();
    const { frequency } = useFormContext().watch();
    const methods = useFormContext();

    const automated = methods.watch('isAutomated') === 'true';

    // Determine which sections to show
    const showWeekly = frequency === Frequency.Weekly;
    const showMonth = frequency === Frequency.Monthly;
    const showQuarterly = frequency === Frequency.Quarterly;
    const showAnnually = frequency === Frequency.Annually;

    return (
        <div className="mt-2 flex-1 items-center w-fit space-y-6">
            <div className="flex gap-2">
                <RadioButton
                    label="Yes"
                    onClick={() => methods.setValue('isAutomated', 'true')}
                    val={automated}
                />
                <RadioButton
                    label="No"
                    val={!automated}
                    onClick={() => methods.setValue('isAutomated', 'false')}
                />
            </div>

            {isAutomated && (
                <section
                    className="flex-1 space-y-6" //
                    data-testid="automated-section"
                >
                    <div className="space-y-3">
                        <CheckboxField
                            name="agreeAutomatedDepositsCheckbox"
                            label={t('automate-future-deposits.automate')}
                        />

                        <CheckboxField
                            name="notifyChangesCheckbox"
                            label={t('automate-future-deposits.notify')}
                        />
                    </div>

                    <div className="space-y-6">
                        <div>
                            <p className="text-md font-medium">
                                {t('automate-future-deposits.frequency')}
                            </p>
                            <p className="text-sm">
                                {t('automate-future-deposits.past-date')}
                            </p>
                        </div>
                        <div className="flex w-full justify-around gap-3">
                            <div className="flex flex-col gap-4 rounded-lg border border-general-grey-grey-40 p-3">
                                <p className="text-md font-medium">
                                    {t('automate-future-deposits.frequency')}
                                </p>
                                <RadioGroupField
                                    name="frequency"
                                    options={frequencyOptions}
                                    required
                                    orientation="vertical"
                                />
                            </div>
                            <FrequencySection
                                showWeekly={showWeekly}
                                showQuarterly={showQuarterly}
                                showMonth={showMonth}
                                showAnnually={showAnnually}
                                allowedHours={hoursOptions}
                                allowedMinutes={minutesOptions}
                            />
                        </div>
                    </div>
                </section>
            )}
        </div>
    );
};

export default AutomateFutureDeposits;
