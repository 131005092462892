import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation } from 'react-query';
import { VerifyRepositoryConfigurationItem } from '../../autogenerated/data-contracts';

import { ApiController } from '../../autogenerated/Api';
import { useAuth } from 'react-oidc-context';
import interceptors from '../../../interceptors';
import securityWorker from '../../../security-worker';

type VerifyRepositoryConfigurationParams = {
    data: VerifyRepositoryConfigurationItem[];
};

type ExtendedVerifyRepositoryConfigurationParams = {
    data: (VerifyRepositoryConfigurationItem & { label: string })[];
};

function useVerifyRepositoryConfiguration<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            AxiosResponse<any>,
            ReactQueryError<TError>,
            ExtendedVerifyRepositoryConfigurationParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const auth = useAuth();

    return useMutation(
        [{ token: auth.user?.access_token, tokenType: auth.user?.token_type }],
        async ({ data }: VerifyRepositoryConfigurationParams) => {
            if (process.env.REACT_APP_API_MOCK) {
                console.warn(
                    'Mock is enabled useVerifyRepositoryConfiguration call ignored.',
                    data
                );
                return Promise.resolve({} as AxiosResponse);
            }

            const controller = new ApiController({
                interceptors,
                securityWorker: securityWorker(
                    auth.user?.token_type,
                    auth.user?.access_token
                )
            });

            return controller.verifyRepositoryConfiguration({
                items: data ?? []
            });
        },
        {
            ...options
        }
    );
}

export default useVerifyRepositoryConfiguration;
export type { VerifyRepositoryConfigurationParams };
